.heading_list_page h1 {
  color: #000;
  font-weight: 600;
  font-size: 25px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 0;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
}
.product_section {
  width: 100%;
  position: relative;
  span {
    position: relative;
  }
  h1 {
    color: #000;
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    padding-top: 30px;
    font-family: Lato, sans-serif;
  }
  .product_image {
    // position: relative;
    // width: 100%;
    // height: 225px;
    cursor: pointer;
  }
  .products_text_top {
    width: 100%;
    height: 87px;
  }
  .largimg {
    margin: 15px 0px;
  }
  .view_all {
    text-align: right;
    a {
      color: #ff1933;
      font-size: 16px;
    }
  }
  .filter_section {
    display: flex;
    justify-content: center;
    width: 75%;
    span {
      line-height: 48px;
      margin-right: 15px;
      color: #777;
      font-weight: 500;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
    }
    .select {
      &:hover {
        .select1 {
          display: block;
        }
      }
    }
    .select1 {
      appearance: none;
      outline: 0;
      border: 0;
      box-shadow: none;
      flex: 1;
      padding: 0 0.5em;
      font-size: 13px;
      color: #000;
      background-color: #fff;
      background-image: none;
      cursor: pointer;
      text-transform: uppercase;
      // display: none;
    }
  }
  .filter_section1 {
    display: flex;
    justify-content: center;
    width: 20%;
    span {
      line-height: 3;
      margin-right: 15px;
      color: #777;
    }
    .select1 {
      appearance: none;
      outline: 0;
      border: 0;
      box-shadow: none;
      flex: 1;
      padding: 0 0.5em;
      font-size: 13px;
      color: #000;
      background-color: #fff;
      background-image: none;
      cursor: pointer;
      text-transform: uppercase;
    }

    .check {
      margin: 10px 0px;
    }
  }
  .filter_section2 {
    display: flex;
    justify-content: center;
    width: 100%;
    span {
      line-height: 48px;
      margin-right: 15px;
      color: #777;
      font-weight: 500;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
    }
    .select1 {
      appearance: none;
      outline: 0;
      border: 0;
      box-shadow: none;
      flex: 1;
      padding: 0 0.5em;
      font-size: 13px;
      color: #000;
      background-color: #fff;
      background-image: none;
      cursor: pointer;
      text-transform: uppercase;
    }

    .check {
      margin: 10px 0px;
    }
  }
  .f-wrap {
    flex-wrap: wrap;
  }
  .products_text_top {
    width: 100%;
    height: 87px;
    clear: both;
    display: flex;
    justify-content: space-between;
  }
  .product {
    display: block;
    width: 100%;
    margin: 0px 0;
    position: relative;
    padding-bottom: 0px;
    background-color: #fff;
    transition: box-shadow 0.3s;
    border: 1px solid #ddd;
    &:hover {
      box-shadow: 0px 4px 19px rgb(33 33 33 / 15%);
      border: 1px solid rgb(33 33 33 / 15%);
    }
    .pad_10 {
      padding: 10px;
    }
    img {
      width: 100% !important;
      object-fit: fill;
    }

    .product_top_left {
      // position: absolute;
      // left: 10px;
      // top: 10px;
      min-height: 87px;
      float: left;
      max-width: 62%;
      .best_seller {
        background-image: url("../assets/images/best-seller.png");
        background-size: 100% 100%;
        padding: 2px 10px;
        color: #fff;
        font-weight: 400;
        font-size: 13px;
        width: auto;
        margin-left: -10px;
        text-transform: uppercase;
        width: 100px;
      }
      .trending {
        background-image: url("../assets/images/trending.png");
        background-size: 100% 100%;
        padding: 2px 10px;
        color: #fff;
        font-weight: 400;
        font-size: 13px;
        text-transform: uppercase;
        margin-left: -10px;
      }
      .popular {
        background-image: url("../assets/images/popular.png");
        background-size: 100% 100%;
        padding: 2px 10px;
        color: #fff;
        font-weight: 400;
        font-size: 13px;
        text-transform: uppercase;
        margin-left: -10px;
        width: 90px;
      }
      .new_arrival {
        background-image: url("../assets/images/new_arrival.png");
        background-size: 100% 100%;
        padding: 2px 10px;
        color: #fff;
        font-weight: 400;
        font-size: 13px;
        text-transform: uppercase;
        margin-left: -10px;
        width: 118px;
      }
      .discount {
        color: #555;
        font-size: 14px;
        padding: 2px 0px;
        font-weight: 600;
      }
      .text {
        color: #000;
        padding: 0px 0px;
        font-size: 13px;
        font-weight: 600;
      }
    }

    .cart_top {
      // position: absolute;
      // right: 10px;
      float: right;
      max-width: 42%;
      display: flex;
      justify-content: flex-end;
      align-items: stretch;
      .fast_delivery,
      a {
        max-width: 23px !important;
        margin: 0 3px !important;
        width: 23px !important;
        cursor: pointer;
        &:last-child {
          margin-right: 0px !important;
        }
        img {
          max-width: 23px !important;
          max-height: 23px !important;
        }
        svg {
          width: 23px;
          height: 23px;
          top: 0px !important;
        }
      }
    }
    .price_cart {
      display: flex;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .price1 {
        font-weight: 600;
        font-size: 17px;
        color: #ff1933;
        span {
          width: 100%;
        }
        .price2 {
          width: 100%;
          font-weight: 400;
          text-decoration: line-through;
          color: #777;
          font-size: 15px;
          span {
            color: #777;
          }
        }
      }

      .details {
        display: flex;
        font-weight: 400;
        i {
          font-size: 24px;
          color: #ff1933;
        }
      }
    }
    .product_details {
      display: block;
      font-weight: 400;
      width: 100%;
      font-size: 15px;
      border-top: 2px solid #fff;
      padding-top: 7px;
      position: relative;
      margin-top: 7px;
      .product_details_text {
        max-width: 60%;
        display: inline-block;
      }
      .find_similar {
        max-width: 40%;
        float: right;
        cursor: pointer;
        color: #ff1933;
      }
      .product_details_full {
        clear: both;
        position: absolute;
        background-color: #f7f7f7;
        z-index: 9999;
        left: 0;
        width: 100%;
        padding: 0px 10px 10px 10px;
        box-shadow: 0px 18px 20px -12px rgb(0 0 0 / 30%);
        p {
          color: #000;
          font-size: 15px;
          margin-bottom: 5px;
          span {
            color: #333;
            font-size: 12px;
            padding: 0 5px;
          }
          img {
            width: 15px !important;
            float: left;
          }
        }
        label {
          color: #ff1933;
          font-size: 15px;
          font-weight: 400;
        }
        .custom_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .col_4 {
            display: flex;
            align-items: center;
            padding-top: 10px;
            p {
              margin-bottom: 0px !important;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .product-3 {
    display: flex;
    flex: 0 0 auto;
    width: 47%;
    align-self: center;
    flex-wrap: wrap;
    img {
      background-color: #f7f7f7;
    }
  }

  .product4 {
    display: flex;
    flex: 0 0 auto;
    width: 45%;
    align-self: center;
    margin: 10px;
    position: relative;
    img {
      background-color: #f7f7f7;
    }
    .wishlist {
      position: absolute;
      left: 5px;
      top: 15px;
    }
    .cart {
      position: absolute;
      right: 10px;
      top: 15px;
    }
    .price {
      display: flex;

      .price1 {
        position: absolute;
        left: 15px;
        bottom: 15px;
        display: flex;
        font-weight: 600;
      }
      .details {
        display: flex;
        position: absolute;
        right: 15px;
        bottom: 15px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
  .product1 {
    display: flex;
    flex: 0 0 auto;
    width: 45%;
    margin: 15px;
    position: relative;
  }
  .checkbox {
    width: 17%;
    .check {
      float: right;
      label {
        margin-right: 10px;
        position: relative;
        .fast {
          margin-top: 5px !important;
        }
      }
    }
  }
  .view_all1 {
    margin-left: 15px;
    color: #777;
  }
}
.position_relative {
  position: relative !important;
  height: 100% !important;
}
.w_100 {
  width: 100%;
  span {
    width: 100% !important;

    height: 100% !important;
  }
}
.custom_height_list {
  height: 100% !important;
  img {
    position: relative !important;
    height: 100% !important;
  }
}
.select2 {
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  flex: 1;
  padding: 0 0.5em;
  font-size: 13px;
  color: #000;
  background-color: #fff;
  background-image: none;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  option {
    line-height: 35px;
  }
}
.bg_slider {
  background-color: #f7f7f7;
  padding: 50px 0px;
  h2 {
    font-size: 35px;
    color: #000;
    margin-bottom: 10px;
  }
  .product {
    background-color: #fff;
    border: 1px solid #777;
    p {
      font-size: 14px !important;
    }
  }
}
.select {
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  flex: 1;
  padding: 0 0.5em;
  font-size: 13px;
  color: #000;
  background-color: #fff;
  background-image: none;
  cursor: pointer;
  text-transform: uppercase;
  margin: 7px 0px;
}
/* Remove IE arrow */
.select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 9em;

  border-radius: 0.25em;
  ul {
    list-style: none;
    background-color: #fff;
    margin: 0px;
    width: 100%;
    li {
      span {
        float: left;
        color: #000;
      }
      i {
        float: right;
        line-height: 36px;
        margin-right: 10px;
      }
      button {
        padding: 10px 9px;
        width: 100%;
        border: 0px;
        border-bottom: 1px solid #ddd;
        background-color: transparent;
        text-align: left;
        color: rgb(51, 54, 62);
        height: 47px;
      }
    }
  }
}
.select1 {
  width: 98% !important;
  position: absolute;
  left: 0px;
  padding: 0px !important;
  top: 100%;
  background: rgb(255, 255, 255);
  transition: visibility 0s ease 0s, opacity 0.4s ease 0s;
  box-shadow: rgb(153 152 163 / 80%) 0px 0px 10px 1px !important;
  border-left: none;
  border-right: none;
  border-bottom: none;
  z-index: 10;
  overflow: hidden auto;
  text-align: left;
  display: none;
}
/* Arrow */
// .select::after {
//   content: "\25BC";
//   position: absolute;
//   top: 0;
//   right: 15px;
//   padding: 0.8em 2px;
//   background-color: #fff;
//   transition: 0.25s all ease;
//   pointer-events: none;
//   color: #000;
// }

.check {
  margin-top: 10px;
  label {
    margin-top: -20px;
  }
}

.show_contant {
  margin-top: 30px;
  width: 100%;
  p {
    font-size: 15px;
  }
  .more_show {
    width: 250px;
    margin: 0 auto;
    background-color: transparent;
    border: 1px solid #222;
    color: #222;
    line-height: 45px;
    display: block;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      background-color: #ff1933;
      color: #fff;
      border: 1px solid #ff1933;
    }
  }
  .backtotop {
    font-size: 16px !important;
    text-align: center;
  }
}
// .Recent Search
.resent_search {
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding-top: 30px;
  h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .col_2 {
    flex: 0 0 auto;
    width: 20%;
    .card_body {
      border: 1px solid #ddd;
      img {
        width: 100%;
      }
      p {
        font-size: 16px;
        color: #000;
        margin-bottom: 0px;
        padding: 0px 10px;
        span {
          color: #777;
          font-size: 16px;
        }
      }
    }
  }
}
.product {
  display: block;
  width: 100%;
  margin: 12px 0;
  position: relative;
  padding-bottom: 0px;
  background-color: #fff;
  text-align: left;
  border: 1px solid #ddd;
  &:hover {
    box-shadow: 0px 4px 19px rgb(33 33 33 / 15%);
    border: 1px solid rgba(33, 33, 33, 0.15);
  }
  .pad_10 {
    padding: 10px;
    width: 100%;
  }
  // img {
  //   width: 100% !important;
  //   object-fit: cover;
  // }

  .product_top_left {
    // position: absolute;
    // left: 10px;
    // top: 10px;
    min-height: 87px;
    float: left;
    max-width: 60%;
    .best_seller {
      background-image: url("../assets/images/best-seller.png");
      background-size: 100% 100%;
      padding: 2px 10px;
      color: #fff;
      font-weight: 400;
      font-size: 13px;
      width: auto;
      margin-left: -10px;
      text-transform: uppercase;
      width: 100px;
    }
    .trending {
      background-image: url("../assets/images/trending.png");
      background-size: 100% 100%;
      padding: 2px 10px;
      color: #fff;
      font-weight: 400;
      font-size: 13px;
      text-transform: uppercase;
      margin-left: -10px;
      width: auto;
      display: inline-block;
    }
    .popular {
      background-image: url("../assets/images/popular.png");
      background-size: 100% 100%;
      padding: 2px 10px;
      color: #fff;
      font-weight: 400;
      font-size: 13px;
      text-transform: uppercase;
      margin-left: -10px;
      width: 90px;
    }
    .new_arrival {
      background-image: url("../assets/images/new_arrival.png");
      background-size: 100% 100%;
      padding: 2px 10px;
      color: #fff;
      font-weight: 400;
      font-size: 13px;
      text-transform: uppercase;
      margin-left: -10px;
      width: 118px;
    }
    .discount {
      color: #555;
      font-size: 14px;
      padding: 2px 0px;
      font-weight: 600;
    }
    .text {
      color: #000;
      padding: 0px 0px;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .cart_top {
    // position: absolute;
    // right: 10px;
    float: right;
    max-width: 42%;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    a {
      max-width: 23px !important;
      margin: 0 3px !important;
      width: 23px !important;
      &:last-child {
        margin-right: 0px !important;
      }
      img {
        max-width: 23px !important;
        max-height: 23px !important;
      }
      svg {
        width: 23px;
        height: 23px;
        top: 0px !important;
      }
    }
  }
  .price_cart {
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    min-height: 48px;
    .price1 {
      font-weight: 600;
      font-size: 17px;
      color: #ff1933;
      min-width: 140px;
      span {
        width: 100%;
      }
      .price2 {
        width: 100%;
        font-weight: 400;
        text-decoration: line-through;
        color: #777;
        font-size: 15px;
        span {
          color: #777;
        }
      }
    }

    .details {
      display: flex;
      font-weight: 400;
      justify-content: flex-end;
      i {
        font-size: 24px;
        color: #ff1933;
      }
    }
  }
  .product_details {
    display: block;
    font-weight: 400;
    width: 100%;
    font-size: 15px;
    border-top: 2px solid #fff;
    padding-top: 7px;
    position: relative;
    margin-top: 7px;
    .product_details_text {
      max-width: 60%;
      display: inline-block;
    }
    .find_similar {
      max-width: 40%;
      float: right;
      cursor: pointer;
      color: #ff1933;
    }
    .product_details_full {
      clear: both;
      position: absolute;
      background-color: #f7f7f7;
      z-index: 9999;
      left: 0;
      width: 100%;
      padding: 0px 10px 10px 10px;
      box-shadow: 0px 18px 20px -12px rgb(0 0 0 / 30%);
      p {
        color: #000;
        font-size: 15px;
        margin-bottom: 5px;
        span {
          color: #333;
          font-size: 12px;
          padding: 0 5px;
        }
        img {
          width: 15px !important;
          float: left;
        }
      }
      label {
        color: #ff1933;
        font-size: 15px;
        font-weight: 400;
      }
      .custom_row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .col_4 {
          display: flex;
          align-items: center;
          padding-top: 10px;
          p {
            margin-bottom: 0px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}
.product_image {
  position: relative;
  width: 100%;
  height: auto;
  // height: 225px;
  cursor: pointer;
  span {
    width: 100% !important;
  }
}
.products_text_top {
  width: 100%;
  height: 87px;
}
.mobile_filter {
  display: none;
  button {
    width: 100%;
    background: #fff;
    display: flex;
    padding: 10px 20px;
    border: 1px solid rgb(255 29 37 / 50%);
    color: rgb(255 29 37);
    justify-content: space-between;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
  }
}
.desktop_filter {
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .desktop_filter {
    display: none;
  }
  .mobile_filter {
    display: block;
  }
  .product_section {
    width: 100%;
    position: relative;
    .product {
      margin: 12px 0 !important;
      padding-bottom: 10px !important;
    }
    span {
      position: relative;
    }
    .product_image {
      // position: relative;
      // width: 100%;
      // height: 200px !important;
      cursor: pointer;
    }
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .product_section {
    .largimg {
      margin-top: 15px;
      margin-bottom: 0px;
      img {
        height: 651px !important;
        object-fit: cover;
      }
    }
    .product {
      padding-bottom: 60px;
      img {
        background-color: #f7f7f7;
      }
      .wishlist {
        position: absolute;
        left: 5px;
        top: 15px;
      }
      .cart {
        position: absolute;
        right: 10px;
        top: 6px;
      }
      .price {
        display: block;

        .price1 {
          position: absolute;
          left: 0;
          bottom: 35px;
          width: 100%;
          width: 100%;
          text-align: center;
          font-weight: 400;
          span {
            width: 100%;
          }
          .price2 {
            width: 100%;
            font-weight: 400;
            text-decoration: line-through;
            width: 100%;
            color: red;
            span {
              color: #777;
            }
          }
        }

        .details {
          display: block;
          position: absolute;
          right: 0px;
          bottom: 10px;
          font-weight: 400;
          text-transform: uppercase;
          width: 100%;
          text-align: center;
        }
      }
    }
    .filter_section {
      display: block;
      justify-content: center;
      width: 100%;
      span {
        line-height: 3;
        margin-right: 15px;
      }
      .select1 {
        appearance: none;
        outline: 0;
        border: 0;
        box-shadow: none;
        flex: 1;
        padding: 0 0.5em;
        font-size: 13px;
        color: #000;
        background-color: #fff;
        background-image: none;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
    .filter_section1 {
      display: block;
      justify-content: center;
      width: 100%;
      span {
        line-height: 3;
        margin-right: 15px;
      }
      .select1 {
        appearance: none;
        outline: 0;
        border: 0;
        box-shadow: none;
        flex: 1;
        padding: 0 0.5em;
        font-size: 13px;
        color: #000;
        background-color: #fff;
        background-image: none;
        cursor: pointer;
        text-transform: uppercase;
      }
      .select {
        width: 100%;
      }
      .check {
        margin: 10px 0px;
      }
    }
    .select {
      position: relative;
      display: flex;
      width: 100%;
      height: 3em;
      border-radius: 0.25em;
      overflow: hidden;
      border: 1px solid #ddd;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .desktop_filter {
    display: none;
  }
  .mobile_filter {
    display: block;
  }
  .product_section {
    .largimg {
      margin-top: 15px;
      margin-bottom: 0px;
      img {
        height: 510px !important;
        object-fit: cover;
      }
    }
    .product {
      padding-bottom: 60px;
      img {
        background-color: #f7f7f7;
      }
      .wishlist {
        position: absolute;
        left: 5px;
        top: 15px;
      }
      .cart {
        position: absolute;
        right: 10px;
        top: 6px;
      }
      .price {
        display: block;

        .price1 {
          position: absolute;
          left: 0;
          bottom: 35px;
          width: 100%;
          width: 100%;
          text-align: center;
          font-weight: 400;
          span {
            width: 100%;
          }
          .price2 {
            width: 100%;
            font-weight: 400;
            text-decoration: line-through;
            width: 100%;
            color: red;
            span {
              color: #777;
            }
          }
        }

        .details {
          display: block;
          position: absolute;
          right: 0px;
          bottom: 10px;
          font-weight: 400;
          text-transform: uppercase;
          width: 100%;
          text-align: center;
        }
      }
    }
    .filter_section {
      display: block;
      justify-content: center;
      width: 100%;
      span {
        line-height: 3;
        margin-right: 15px;
      }
      .select1 {
        appearance: none;
        outline: 0;
        border: 0;
        box-shadow: none;
        flex: 1;
        padding: 0 0.5em;
        font-size: 13px;
        color: #000;
        background-color: #fff;
        background-image: none;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
    .filter_section1 {
      display: block;
      justify-content: center;
      width: 100%;
      span {
        line-height: 3;
        margin-right: 15px;
      }
      .select1 {
        appearance: none;
        outline: 0;
        border: 0;
        box-shadow: none;
        flex: 1;
        padding: 0 0.5em;
        font-size: 13px;
        color: #000;
        background-color: #fff;
        background-image: none;
        cursor: pointer;
        text-transform: uppercase;
      }
      .select {
        width: 100%;
      }
      .check {
        margin: 10px 0px;
      }
    }
    .select {
      position: relative;
      display: flex;
      width: 100%;
      height: 3em;
      border-radius: 0.25em;

      border: 1px solid #ddd;
    }
  }
}
@media (min-width: 220px) and (max-width: 767px) {
  .desktop_filter {
    display: none;
  }
  .mobile_filter {
    display: block;
    margin-bottom: 15px;
  }
  .breadcrumb_main {
    display: flex;
    justify-content: center;
  }
  .product_section {
    .filter_section {
      display: block;
      justify-content: center;
      width: 100%;
      span {
        line-height: 3;
        margin-right: 15px;
        font-weight: 500;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        display: none;
      }
      .select1 {
        appearance: none;
        outline: 0;
        border: 0;
        box-shadow: none;
        flex: 1;
        padding: 0 0.5em;
        font-size: 13px;
        color: #000;
        background-color: #fff;
        background-image: none;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
    .filter_section1 {
      display: block;
      justify-content: center;
      width: 100%;
      span {
        line-height: 48px;
        margin-right: 15px;
        font-weight: 500;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        display: none;
      }
      .select1 {
        appearance: none;
        outline: 0;
        border: 0;
        box-shadow: none;
        flex: 1;
        padding: 0 0.5em;
        font-size: 13px;
        color: #000;
        background-color: #fff;
        background-image: none;
        cursor: pointer;
        text-transform: uppercase;
      }
      .select {
        width: 100%;
      }
      .check {
        margin: 10px 0px;
      }
    }
    .select {
      position: relative;
      display: flex;
      width: 100%;
      height: 3em;
      border-radius: 0.25em;
      overflow: hidden;
      border: 1px solid #ddd;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .product_section {
    width: 100%;
    position: relative;
    .product {
      margin: 12px 0 !important;
      padding-bottom: 10px !important;
    }
    span {
      position: relative;
    }
    .product_image {
      // position: relative;
      // width: 100%;
      // height: 250px !important;
      cursor: pointer;
    }
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .product_section {
    width: 100%;
    position: relative;
    span {
      position: relative;
    }
    .product_image {
      position: relative;
      width: 200px;
      max-width: 200px;
      margin: 0 auto;
      height: 200px !important;
      cursor: pointer;
      img {
        object-fit: contain !important;
      }
    }
    .price_cart {
      display: flex;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      min-height: 48px;
      .price1 {
        font-weight: 600;
        font-size: 17px;
        color: #ff1933;
        min-width: 120px;
        span {
          width: 100%;
        }
      }
    }
    .product_top_left {
      // position: absolute;
      // left: 10px;
      // top: 10px;
      min-height: 87px;
      float: left;
      max-width: 62%;

      .discount {
        color: #ff1933;
        font-size: 12px;
        padding: 2px 0px;
        font-weight: 600;
        line-height: 16px;
      }
      .text {
        color: #000;
        padding: 0px 0px;
        font-size: 12px !important;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }
  .price_cart {
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .price1 {
      font-weight: 600;
      font-size: 15px !important;
      color: #ff1933;
      span {
        width: 100%;
        font-size: 15px;
      }
      .price2 {
        width: 100%;
        font-weight: 400;
        text-decoration: line-through;
        color: #777;
        font-size: 12px !important;
        span {
          color: #777;
          font-size: 14px !important;
        }
      }
    }

    .details {
      display: flex;
      font-weight: 400;
      i {
        font-size: 20px;
        color: #ff1933;
      }
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 639px) {
  .products_text_top {
    width: 100%;
    height: 80px;
    clear: both;
    display: flex;
    justify-content: space-between;
  }
  .product_section {
    width: 100%;
    position: relative;
    span {
      position: relative;
    }
    .product_image {
      // position: relative;
      // width: 100%;
      // height: 200px !important;
      cursor: pointer;
      img {
        object-fit: contain !important;
      }
    }
    .price_cart {
      display: flex;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      min-height: 48px;
      .price1 {
        font-weight: 600;
        font-size: 17px;
        color: #ff1933;
        min-width: 120px;
        span {
          width: 100%;
        }
      }
    }
    .product_top_left {
      // position: absolute;
      // left: 10px;
      // top: 10px;
      min-height: 87px;
      float: left;
      max-width: 62%;

      .discount {
        color: #ff1933;
        font-size: 12px;
        padding: 2px 0px;
        font-weight: 600;
        line-height: 16px;
      }
      .text {
        color: #000;
        padding: 0px 0px;
        font-size: 12px !important;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }
  .price_cart {
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .price1 {
      font-weight: 600;
      font-size: 15px !important;
      color: #ff1933;
      span {
        width: 100%;
        font-size: 15px;
      }
      .price2 {
        width: 100%;
        font-weight: 400;
        text-decoration: line-through;
        color: #777;
        font-size: 12px !important;
        span {
          color: #777;
          font-size: 14px !important;
        }
      }
    }

    .details {
      display: flex;
      font-weight: 400;
      i {
        font-size: 20px;
        color: #ff1933;
      }
    }
  }
  .product_top_left {
    // position: absolute;
    // left: 10px;
    // top: 10px;
    min-height: 80px !important;
    float: left;
    max-width: 62%;

    .discount {
      color: #ff1933;
      font-size: 12px !important;
      padding: 2px 0px;
      font-weight: 400;
      line-height: 16px;
      font-family: "Open Sans", sans-serif;
    }
    .text {
      color: #000;
      padding: 0px 0px;
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 16px;
      font-family: "Open Sans", sans-serif;
    }
  }
  .price_cart {
    display: block !important;
    justify-content: space-between;
    align-items: flex-end;

    .price1 {
      font-weight: 600;
      font-size: 16px;
      color: #ff1933;
      min-width: 100% !important;
      max-width: 100% !important;
      min-height: 24px !important;
      span {
        width: 100%;
      }
      .price2 {
        width: 100%;
        font-weight: 400;
        text-decoration: line-through;
        color: #777;
        font-size: 14px;
        span {
          color: #777;
        }
      }
    }

    .details {
      display: block !important;
      font-weight: 400;
      min-height: 38px;
      i {
        font-size: 24px;
        color: #ff1933;
      }
    }
  }
}
@media only screen and (min-width: 220px) and (max-width: 479px) {
  .products_text_top {
    width: 100%;
    height: 80px;
    clear: both;
    display: flex;
    justify-content: space-between;
  }
  .pad_10 {
    padding: 5px !important;
    width: 100%;
  }
  .product_section {
    width: 100%;
    position: relative;
    span {
      position: relative;
    }
    .product_image {
      // position: relative;
      // width: 100%;
      // height: 150px !important;
      cursor: pointer;
    }
    .price_cart {
      display: block !important;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      min-height: 48px;
      .price1 {
        font-weight: 600;
        font-size: 17px;
        color: #ff1933;
        min-width: 120px;
        span {
          width: 100%;
        }
      }
    }
    .product_top_left {
      // position: absolute;
      // left: 10px;
      // top: 10px;
      min-height: 87px;
      float: left;
      max-width: 62%;

      .discount {
        color: #ff1933;
        font-size: 12px;
        padding: 2px 0px;
        font-weight: 600;
        line-height: 16px;
      }
      .text {
        color: #000;
        padding: 0px 0px;
        font-size: 12px !important;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }
  .price_cart {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .price1 {
      font-weight: 600;
      font-size: 14px !important;
      color: #ff1933;
      display: flex;
      font-family: "Open Sans", sans-serif;
      span {
        width: 100%;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
      }
      .price2 {
        width: 100%;
        font-weight: 400;
        text-decoration: line-through;
        color: #777;
        font-size: 12px !important;
        line-height: 24px !important;
        padding-left: 5px !important;
        font-family: "Open Sans", sans-serif;
        span {
          color: #777;
          font-size: 12px !important;
          font-family: "Open Sans", sans-serif;
        }
      }
    }

    .details {
      display: flex;
      font-weight: 400;
      i {
        font-size: 20px;
        color: #ff1933;
      }
    }
  }
  .product_image {
    // position: relative;
    // width: 100%;
    // height: 150px;
    cursor: pointer;
  }
  .product_top_left {
    // position: absolute;
    // left: 10px;
    // top: 10px;
    min-height: 80px !important;
    float: left;
    max-width: 62%;

    .discount {
      color: #ff1933;
      font-size: 12px !important;
      padding: 2px 0px;
      font-weight: 400;
      line-height: 16px;
      font-family: "Open Sans", sans-serif;
    }
    .text {
      color: #000;
      padding: 0px 0px;
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 16px;
      font-family: "Open Sans", sans-serif;
    }
  }
  .price_cart {
    display: block !important;
    justify-content: space-between;
    align-items: flex-end;

    .price1 {
      font-weight: 600;
      font-size: 16px;
      color: #ff1933;
      min-width: 100% !important;
      max-width: 100% !important;
      min-height: 24px !important;
      span {
        width: 100%;
      }
      .price2 {
        width: 100%;
        font-weight: 400;
        text-decoration: line-through;
        color: #777;
        font-size: 14px;
        span {
          color: #777;
        }
      }
    }

    .details {
      display: block !important;
      font-weight: 400;
      min-height: 38px;
      i {
        font-size: 24px;
        color: #ff1933;
      }
    }
  }
}
.product_text_d {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0px;
  padding-top: 8px;
  color: #000;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  padding-bottom: 3px;
}
@media (min-width: 320px) and (max-width: 1199px) {
  .product_text_d {
    font-size: 13px !important;
  }
  .filter_section {
    display: flex;
    justify-content: center;
    width: 100%;
    span {
      line-height: 3;
      margin-right: 15px;
      color: #777;
    }
    .select1 {
      appearance: none;
      outline: 0;
      border: 0;
      box-shadow: none;
      flex: 1;
      padding: 0 0.5em;
      font-size: 13px;
      color: #000;
      background-color: #fff;
      background-image: none;
      cursor: pointer;
      text-transform: uppercase;
      // display: none;
    }
  }
  .checkbox {
    width: 95% !important;
    margin: 0 auto !important;
    padding-bottom: 8px;
    .check {
      float: left !important;
      label {
        margin-right: 10px;
        position: relative;
        .fast {
          margin-top: 2px !important;
        }
      }
    }
  }
}
.banner {
  width: 100%;
  position: relative;
  height: 300px;
  span {
    width: 100% !important;
    img {
      width: 100% !important;
    }
  }
}
.tryontext {
  float: left;
  cursor: pointer;
  span {
    background-color: #ff1933;
    color: #fff;
    width: 45px;
    display: inline-block;
    padding: 2px 3px;
    font-size: 14px;
    border-radius: 5px;
  }
}
.similar_button {
  display: none;
  position: absolute;
  bottom: 92px;
  left: 15px;
  right: 15px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.product {
  &:hover {
    .similar_button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #fff;
      line-height: 35px;
      color: #222;
      border: 1px solid #ddd;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      .text {
        margin-left: 4px !important;
      }
      img {
        width: 15px !important;
        height: 15px !important;
      }
    }
  }
}

.similar_button_mobile {
  display: none;
  position: absolute;
  bottom: 94px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #f2f2f2;
  right: 15px;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  img,
  span {
    width: 14px !important;
    height: 14px !important;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 2000px) {
  .banner {
    height: 400px !important;
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .banner {
    height: 350px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner {
    height: 240px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner {
    height: 175px !important;
  }
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .banner {
    height: 150px !important;
  }
  .similar_button {
    display: none;
  }
  .similar_button_mobile {
    display: block;
  }
  .product {
    &:hover {
      .similar_button {
        display: none;
      }
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 639px) {
  .banner {
    height: 100px !important;
  }
  .similar_button {
    display: none;
  }
  .similar_button_mobile {
    display: block;
  }
  .product {
    &:hover {
      .similar_button {
        display: none;
      }
    }
  }
}
@media only screen and (min-width: 220px) and (max-width: 479px) {
  .banner {
    height: 90px !important;
  }
  .similar_button {
    display: none;
  }
  .similar_button_mobile {
    display: block;
  }
  .product {
    &:hover {
      .similar_button {
        display: none;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .custom_height_list {
    height: 400px !important;
    margin: 12px 0 !important;
  }
  .live_banner_image {
    width: 100%;
    height: 190px;
    img {
      width: 100% !important;
      object-fit: cover;
      height: 190px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .custom_height_list {
    height: 529px !important;
  }
  .live_banner_image {
    width: 100%;
    height: 140px;
    img {
      width: 100% !important;
      object-fit: cover;
      height: 140px;
    }
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .custom_height_list {
    height: 400px !important;
  }
  .live_banner_image {
    width: 100%;
    height: 92px;
    img {
      width: 100% !important;
      object-fit: cover;
      height: 92px;
    }
  }
}
@media (min-width: 480px) and (max-width: 639px) {
  .custom_height_list {
    height: 270px !important;
  }
  .live_banner_image {
    width: 100%;
    height: 92px;
    img {
      width: 100% !important;
      object-fit: cover;
      height: 92px;
    }
  }
}
@media (min-width: 320px) and (max-width: 479px) {
  .custom_height_list {
    height: 270px !important;
  }
  .live_banner_image {
    width: 100%;
    height: 58px;
    img {
      width: 100% !important;
      object-fit: cover;
      height: 58px;
    }
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .best_seller,
  .trending,
  .popular,
  .new_arrival {
    width: 96% !important;
    padding: 2px 5px !important;
    text-align: center;
    min-width: 85px;
    margin-left: -6px !important;
    font-size: 11px !important;
  }
  .tryontext {
    float: left;
    cursor: pointer;
    span {
      top: -5px;
      position: relative;
      font-size: 8px !important;
      padding: 0px;
      width: 23px;
    }
  }
  .cart_top {
    a {
      top: -3px !important;
      position: relative;
      margin: 0px !important;
      svg {
        width: 12px !important;
        height: 12px !important;
      }
    }
  }
}
.product_list_contant {
  width: 100%;
  position: relative;
  margin-top: 30px;
  .product_heading_text {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .product_bottomg_text {
    font-size: 16px;
    margin-bottom: 20px;
    display: block;
    li,
    ul,
    ol {
      margin: revert !important;
      padding: revert !important;
    }
  }
}
.accordion_container {
  margin-bottom: 60px;
  position: relative;
  margin-top: 30px;
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0
      var(--bs-accordion-border-color);
  }
  :global(.accordion-button) {
    &::not(.collapsed) {
      background-color: rgb(133 133 133 / 9%);
      color: #222;
      box-shadow: none;
      font-size: 17px;
    }
    &:focus {
      background-color: rgb(133 133 133 / 9%);
      color: #222;
      box-shadow: none;
      font-size: 17px;
    }
  }
  :global(.accordion-body) {
    li,
    ul,
    ol {
      margin: revert !important;
      padding: revert !important;
    }
  }
  [aria-expanded="true"] {
    background-color: rgb(133 133 133 / 9%);
    color: #222;
    box-shadow: none;
    font-size: 17px;
  }
}
